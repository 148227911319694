import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import Img from 'gatsby-image';
import { formatDate } from '../utils/formatDate';

export const JournalTemplate = ({ posts, fromPage }) => {
  const [isSafari, setIsSafari] = useState(undefined);

  useEffect(() => {
    if (navigator) {
      setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
    }
  }, []);

  const frontPost = posts[0];
  console.log({ posts });
  return (
    <>
      <div>
        <FrontImageContainer>
          <FrontImage imageUrl={frontPost.gallery_image.asset.url}>
            <div>
              <p> JOURNAL | {formatDate(frontPost._createdAt)}</p>
              <h1> {frontPost.title} </h1>
              <p>
                <Link to={`/${fromPage}/journal/${frontPost.slug.current}`}>
                  VIEW POST
                </Link>
              </p>
            </div>
          </FrontImage>
        </FrontImageContainer>
      </div>

      <BlogPostWrapper>
        {posts.map((post, i) => (
          <div className="blog-post-flex" key={i}>
            <div className="flex-image">
              <Link to={`/${fromPage}/journal/${post.slug.current}`}>
                <NewEntry>
                  <div className="entry-text-container">
                    <EntryTextContent isSafari={isSafari}>
                      <p className="entry-text-date">
                        JOURNAL: {formatDate(post._createdAt)}
                      </p>
                      <h2> {post.title} </h2>
                      <div className="blog-desktop">
                        <BlockContent blocks={post._rawBoldTopText} />
                      </div>
                    </EntryTextContent>
                    <p className="read-more"> Read more </p>
                  </div>
                  <NewEntryImgContainer>
                    <Img
                      className="speaker-image"
                      fluid={post.gallery_image.asset.fluid}
                      alt={post.title}
                    />
                  </NewEntryImgContainer>
                </NewEntry>
              </Link>
            </div>
          </div>
        ))}
      </BlogPostWrapper>
    </>
  );
};

const NewEntry = styled.div`
  padding: 30px 0;
  width: 100%;
  max-height: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  @media screen and (max-width: 868px) {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .entry-text-container {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    width: 90%;
    z-index: 3;
    margin-top: 15px;
    background-color: #f5f4ed;
    margin-right: 100px;
    margin-left: -10px;
    margin-bottom: 15px;
    @media screen and (max-width: 1200px) {
      margin-right: 0;
      width: 90vw;
    }
    @media screen and (max-width: 868px) {
      margin-top: -22px;
      margin-right: 4%;
      margin-left: 4%;
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
    }

    @media screen and (max-width: 600px) {
      max-width: 92%;
    }

    .read-more {
      margin: 34px 0 10px 31px;
      color: #2c2c2c;
      font-size: 15px;
      line-height: 40px;
      -webkit-letter-spacing: 0.04px;
      -moz-letter-spacing: 0.04px;
      -ms-letter-spacing: 0.04px;
      letter-spacing: 0.04px;
      font-style: normal;
      font-family: RalewayBold;
    }
  }
`;

const BlogPostWrapper = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 5%;

  .blog-post-flex {
    width: 100%;
    max-width: 1200px;
  }

  .blog-post-flex:first-child .flex-image .entry-text-container {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 70%;
    z-index: 3;
    background-color: #f5f4ed;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: -10px;
    margin-left: 100px;
    @media screen and (max-width: 1200px) {
      margin-left: 0;
    }
    @media screen and (max-width: 868px) {
      width: 100%;
      min-height: 220px;
      margin-top: -22px;
      margin-right: 4%;
      margin-left: 4%;
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
    }
  }

  .blog-post-flex .flex-image .entry-text-container {
    @media screen and (max-width: 868px) {
      min-height: 220px;
    }
  }

  .blog-post-flex:nth-child(odd) .flex-image .entry-text-container {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 90%;
    z-index: 3;
    background-color: #f5f4ed;
    margin-top: 15px;
    margin-right: -10px;
    margin-left: 100px;
    margin-bottom: 15px;
    @media screen and (max-width: 1200px) {
      margin-left: 0;
    }
    @media screen and (max-width: 868px) {
      min-height: 220px;
      margin-top: -22px;
      margin-right: 4%;
      margin-left: 4%;
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
    }
  }
`;

const NewEntryImgContainer = styled.div`
  z-index: 0;
  width: 100%;
  max-height: 372px;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0 0 20px;
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 20px;

  @media screen and (max-width: 1000px) {
    width: 105%;
  }

  .speaker-image {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
`;

const EntryTextContent = styled.div`
  .entry-text-date {
    font-family: RalewayMedium;
    font-weight: bold;
  }
  margin: -10px 31px;

  h2 {
    color: #2c2c2c;
    font-size: 40px;
    margin-bottom: 20px;
    line-height: 44px;
    letter-spacing: 0.04px;
    @media screen and (max-width: 868px) {
      font-size: 25px;
      line-height: 30px;
      letter-spacing: 0.02px;
    }
  }

  h5 {
    margin-top: 50px;
    display: block;
    width: 100%;
    color: #212121;
    font-family: 'raleway-semibold';
    font-size: 15px;
    line-height: 40px;
    @media screen and (max-width: 868px) {
      font-size: 12px;
      line-height: 40px;
    }
  }

  p {
    margin-top: 34px;
    margin-bottom: 10px;
    color: #2c2c2c;
    font-size: 14px;
    font-weight: 300;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    letter-spacing: 0.04px;
    @media screen and (max-width: 868px) {
      font-size: 13px;
      line-height: 19px;
    }

    &:last-child {
      margin: 0;
    }
  }

  .blog-desktop {
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: ${(p) => (p.isSafari ? 5 : 7)};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    p {
      &:first-child {
        margin-top: 0;
      }
    }

    @media screen and (max-width: 600px) {
      min-width: 100%;
    }
  }
`;

const FrontImageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FrontImage = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-position: center center;
  background-size: cover;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url(${(p) => p.imageUrl});
  &:before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 999;
    color: var(--white);
    h1 {
      font-size: 50px;
      line-height: 67px;
      letter-spacing: 0.05px;
      max-width: 600px;
      text-align: center;
    }
    p {
      min-width: 100%;
      text-align: center;
      margin-bottom: 0;
      &:first-child {
        font-size: 19px;
        line-height: 40px;
        font-weight: bold;
        margin: 0;
      }
      a {
        text-transform: uppercase;
        color: var(--white);
        font-weight: bold;
        font-size: 19px;
        line-height: 40px;
        border-color: #f5f4ed;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        font-family: RalewayRegular;
        font-style: normal;
      }
    }
  }
`;
