import React from 'react';
import { graphql } from 'gatsby';
import { Logo } from '../../components/Logo';
import { SEO } from '../../components/SEO';
import { JournalTemplate } from '../../components/JournalTemplate';

export default function Journal({ data }) {
  const { nodes } = data.posts;
  const posts = nodes;

  return (
    <>
      <Logo color="white" />
      <SEO title="Rumblewood narrative journal" />
      <JournalTemplate posts={posts} fromPage="narrative" />
    </>
  );
}

export const query = graphql`
  {
    posts: allSanityNarrativeJournalSinglePages(sort: { fields: order }) {
      nodes {
        _createdAt
        gallery_image {
          asset {
            url
            fluid(maxWidth: 540, maxHeight: 370) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _rawBoldTopText(resolveReferences: { maxDepth: 10 })
        title
        slug {
          current
        }
      }
    }
  }
`;
